export const ROLE_ADMIN = 'admin';
export const ROLE_COLABORADOR = 'colaborador';

export const PERMISSION_AUTHENTICATION_ADD_ACCOUNT = 'authentication.add_account';
export const PERMISSION_AUTHENTICATION_ADD_VERIFICATIONTOKEN = 'authentication.add_verificationtoken';
export const PERMISSION_AUTHENTICATION_CHANGE_ACCOUNT = 'authentication.change_account';
export const PERMISSION_AUTHENTICATION_CHANGE_VERIFICATIONTOKEN = 'authentication.change_verificationtoken';
export const PERMISSION_AUTHENTICATION_DELETE_ACCOUNT = 'authentication.delete_account';
export const PERMISSION_AUTHENTICATION_DELETE_VERIFICATIONTOKEN = 'authentication.delete_verificationtoken';
export const PERMISSION_AUTHENTICATION_VIEW_ACCOUNT = 'authentication.view_account';
export const PERMISSION_AUTHENTICATION_VIEW_VERIFICATIONTOKEN = 'authentication.view_verificationtoken';
export const PERMISSION_KEY_RESULTS_ADD_ANALISEDETALHADA = 'key_results.add_analisedetalhada';
export const PERMISSION_KEY_RESULTS_ADD_CHECKIN = 'key_results.add_checkin';
export const PERMISSION_KEY_RESULTS_ADD_KEYRESULT = 'key_results.add_keyresult';
export const PERMISSION_KEY_RESULTS_ADD_KEYRESULTHISTORY = 'key_results.add_keyresulthistory';
export const PERMISSION_KEY_RESULTS_ADD_PLANOACAO = 'key_results.add_planoacao';
export const PERMISSION_KEY_RESULTS_CHANGE_ANALISEDETALHADA = 'key_results.change_analisedetalhada';
export const PERMISSION_KEY_RESULTS_CHANGE_CHECKIN = 'key_results.change_checkin';
export const PERMISSION_KEY_RESULTS_CHANGE_KEYRESULT = 'key_results.change_keyresult';
export const PERMISSION_KEY_RESULTS_CHANGE_KEYRESULTHISTORY = 'key_results.change_keyresulthistory';
export const PERMISSION_KEY_RESULTS_CHANGE_PLANOACAO = 'key_results.change_planoacao';
export const PERMISSION_KEY_RESULTS_DELETE_ANALISEDETALHADA = 'key_results.delete_analisedetalhada';
export const PERMISSION_KEY_RESULTS_DELETE_CHECKIN = 'key_results.delete_checkin';
export const PERMISSION_KEY_RESULTS_DELETE_KEYRESULT = 'key_results.delete_keyresult';
export const PERMISSION_KEY_RESULTS_DELETE_KEYRESULTHISTORY = 'key_results.delete_keyresulthistory';
export const PERMISSION_KEY_RESULTS_DELETE_PLANOACAO = 'key_results.delete_planoacao';
export const PERMISSION_KEY_RESULTS_VIEW_ANALISEDETALHADA = 'key_results.view_analisedetalhada';
export const PERMISSION_KEY_RESULTS_VIEW_CHECKIN = 'key_results.view_checkin';
export const PERMISSION_KEY_RESULTS_VIEW_KEYRESULT = 'key_results.view_keyresult';
export const PERMISSION_KEY_RESULTS_VIEW_KEYRESULTHISTORY = 'key_results.view_keyresulthistory';
export const PERMISSION_KEY_RESULTS_VIEW_PLANOACAO = 'key_results.view_planoacao';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_ADD_AREA = 'organizational_structure.add_area';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_ADD_COMPANY = 'organizational_structure.add_company';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_ADD_PERIOD = 'organizational_structure.add_period';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_ADD_TEAM = 'organizational_structure.add_team';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_CHANGE_AREA = 'organizational_structure.change_area';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_CHANGE_COMPANY = 'organizational_structure.change_company';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_CHANGE_PERIOD = 'organizational_structure.change_period';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_CHANGE_TEAM = 'organizational_structure.change_team';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_DELETE_AREA = 'organizational_structure.delete_area';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_DELETE_COMPANY = 'organizational_structure.delete_company';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_DELETE_PERIOD = 'organizational_structure.delete_period';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_DELETE_TEAM = 'organizational_structure.delete_team';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_VIEW_AREA = 'organizational_structure.view_area';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_VIEW_COMPANY = 'organizational_structure.view_company';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_VIEW_PERIOD = 'organizational_structure.view_period';
export const PERMISSION_ORGANIZATIONAL_STRUCTURE_VIEW_TEAM = 'organizational_structure.view_team';
export const PERMISSION_USERS_ADD_BASEUSER = 'users.add_baseuser';
export const PERMISSION_USERS_ADD_INVITE = 'users.add_invite';
export const PERMISSION_USERS_ADD_TENANT = 'users.add_tenant';
export const PERMISSION_USERS_CHANGE_BASEUSER = 'users.change_baseuser';
export const PERMISSION_USERS_CHANGE_INVITE = 'users.change_invite';
export const PERMISSION_USERS_CHANGE_TENANT = 'users.change_tenant';
export const PERMISSION_USERS_DELETE_BASEUSER = 'users.delete_baseuser';
export const PERMISSION_USERS_DELETE_INVITE = 'users.delete_invite';
export const PERMISSION_USERS_DELETE_TENANT = 'users.delete_tenant';
export const PERMISSION_USERS_LIST_BASEUSER = 'users.list_baseuser';
export const PERMISSION_USERS_LIST_INVITE = 'users.list_invite';
export const PERMISSION_USERS_VIEW_BASEUSER = 'users.view_baseuser';
export const PERMISSION_USERS_VIEW_INVITE = 'users.view_invite';
export const PERMISSION_USERS_VIEW_TENANT = 'users.view_tenant';
export const PERMISSION_TOKEN_BLACKLIST_ADD_BLACKLISTEDTOKEN = 'token_blacklist.add_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_ADD_OUTSTANDINGTOKEN = 'token_blacklist.add_outstandingtoken';
export const PERMISSION_TOKEN_BLACKLIST_CHANGE_BLACKLISTEDTOKEN = 'token_blacklist.change_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_CHANGE_OUTSTANDINGTOKEN = 'token_blacklist.change_outstandingtoken';
export const PERMISSION_TOKEN_BLACKLIST_DELETE_BLACKLISTEDTOKEN = 'token_blacklist.delete_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_DELETE_OUTSTANDINGTOKEN = 'token_blacklist.delete_outstandingtoken';
export const PERMISSION_TOKEN_BLACKLIST_VIEW_BLACKLISTEDTOKEN = 'token_blacklist.view_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_VIEW_OUTSTANDINGTOKEN = 'token_blacklist.view_outstandingtoken';
export const PERMISSION_DJANGO_CELERY_RESULTS_ADD_CHORDCOUNTER = 'django_celery_results.add_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_ADD_GROUPRESULT = 'django_celery_results.add_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_ADD_TASKRESULT = 'django_celery_results.add_taskresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_CHANGE_CHORDCOUNTER = 'django_celery_results.change_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_CHANGE_GROUPRESULT = 'django_celery_results.change_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_CHANGE_TASKRESULT = 'django_celery_results.change_taskresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_DELETE_CHORDCOUNTER = 'django_celery_results.delete_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_DELETE_GROUPRESULT = 'django_celery_results.delete_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_DELETE_TASKRESULT = 'django_celery_results.delete_taskresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_VIEW_CHORDCOUNTER = 'django_celery_results.view_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_VIEW_GROUPRESULT = 'django_celery_results.view_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_VIEW_TASKRESULT = 'django_celery_results.view_taskresult';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_CLOCKEDSCHEDULE = 'django_celery_beat.add_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_CRONTABSCHEDULE = 'django_celery_beat.add_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_INTERVALSCHEDULE = 'django_celery_beat.add_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_PERIODICTASK = 'django_celery_beat.add_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_PERIODICTASKS = 'django_celery_beat.add_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_SOLARSCHEDULE = 'django_celery_beat.add_solarschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_CLOCKEDSCHEDULE = 'django_celery_beat.change_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_CRONTABSCHEDULE = 'django_celery_beat.change_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_INTERVALSCHEDULE = 'django_celery_beat.change_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_PERIODICTASK = 'django_celery_beat.change_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_PERIODICTASKS = 'django_celery_beat.change_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_SOLARSCHEDULE = 'django_celery_beat.change_solarschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_CLOCKEDSCHEDULE = 'django_celery_beat.delete_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_CRONTABSCHEDULE = 'django_celery_beat.delete_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_INTERVALSCHEDULE = 'django_celery_beat.delete_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_PERIODICTASK = 'django_celery_beat.delete_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_PERIODICTASKS = 'django_celery_beat.delete_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_SOLARSCHEDULE = 'django_celery_beat.delete_solarschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_CLOCKEDSCHEDULE = 'django_celery_beat.view_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_CRONTABSCHEDULE = 'django_celery_beat.view_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_INTERVALSCHEDULE = 'django_celery_beat.view_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_PERIODICTASK = 'django_celery_beat.view_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_PERIODICTASKS = 'django_celery_beat.view_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_SOLARSCHEDULE = 'django_celery_beat.view_solarschedule';
export const PERMISSION_CONTENTTYPES_ADD_CONTENTTYPE = 'contenttypes.add_contenttype';
export const PERMISSION_CONTENTTYPES_CHANGE_CONTENTTYPE = 'contenttypes.change_contenttype';
export const PERMISSION_CONTENTTYPES_DELETE_CONTENTTYPE = 'contenttypes.delete_contenttype';
export const PERMISSION_CONTENTTYPES_VIEW_CONTENTTYPE = 'contenttypes.view_contenttype';
export const PERMISSION_EMAILS_ADD_EMAIL = 'emails.add_email';
export const PERMISSION_EMAILS_ADD_EMAILTEMPLATE = 'emails.add_emailtemplate';
export const PERMISSION_EMAILS_CHANGE_EMAIL = 'emails.change_email';
export const PERMISSION_EMAILS_CHANGE_EMAILTEMPLATE = 'emails.change_emailtemplate';
export const PERMISSION_EMAILS_DELETE_EMAIL = 'emails.delete_email';
export const PERMISSION_EMAILS_DELETE_EMAILTEMPLATE = 'emails.delete_emailtemplate';
export const PERMISSION_EMAILS_VIEW_EMAIL = 'emails.view_email';
export const PERMISSION_EMAILS_VIEW_EMAILTEMPLATE = 'emails.view_emailtemplate';
export const PERMISSION_KANBAN_ADD_ETAPA = 'kanban.add_etapa';
export const PERMISSION_KANBAN_ADD_TICKET = 'kanban.add_ticket';
export const PERMISSION_KANBAN_CHANGE_ETAPA = 'kanban.change_etapa';
export const PERMISSION_KANBAN_CHANGE_TICKET = 'kanban.change_ticket';
export const PERMISSION_KANBAN_DELETE_ETAPA = 'kanban.delete_etapa';
export const PERMISSION_KANBAN_DELETE_TICKET = 'kanban.delete_ticket';
export const PERMISSION_KANBAN_VIEW_ETAPA = 'kanban.view_etapa';
export const PERMISSION_KANBAN_VIEW_TICKET = 'kanban.view_ticket';
export const PERMISSION_FILES_ADD_FILE = 'files.add_file';
export const PERMISSION_FILES_CHANGE_FILE = 'files.change_file';
export const PERMISSION_FILES_DELETE_FILE = 'files.delete_file';
export const PERMISSION_FILES_VIEW_FILE = 'files.view_file';
export const PERMISSION_AUTH_ADD_GROUP = 'auth.add_group';
export const PERMISSION_AUTH_ADD_PERMISSION = 'auth.add_permission';
export const PERMISSION_AUTH_CHANGE_GROUP = 'auth.change_group';
export const PERMISSION_AUTH_CHANGE_PERMISSION = 'auth.change_permission';
export const PERMISSION_AUTH_DELETE_GROUP = 'auth.delete_group';
export const PERMISSION_AUTH_DELETE_PERMISSION = 'auth.delete_permission';
export const PERMISSION_AUTH_VIEW_GROUP = 'auth.view_group';
export const PERMISSION_AUTH_VIEW_PERMISSION = 'auth.view_permission';
export const PERMISSION_LOGGER_ADD_LOG = 'logger.add_log';
export const PERMISSION_LOGGER_CHANGE_LOG = 'logger.change_log';
export const PERMISSION_LOGGER_DELETE_LOG = 'logger.delete_log';
export const PERMISSION_LOGGER_VIEW_LOG = 'logger.view_log';
export const PERMISSION_ADMIN_ADD_LOGENTRY = 'admin.add_logentry';
export const PERMISSION_ADMIN_CHANGE_LOGENTRY = 'admin.change_logentry';
export const PERMISSION_ADMIN_DELETE_LOGENTRY = 'admin.delete_logentry';
export const PERMISSION_ADMIN_VIEW_LOGENTRY = 'admin.view_logentry';
export const PERMISSION_OBJECTIVES_ADD_OBJECTIVE = 'objectives.add_objective';
export const PERMISSION_OBJECTIVES_ADD_OBJECTIVEHISTORY = 'objectives.add_objectivehistory';
export const PERMISSION_OBJECTIVES_CHANGE_OBJECTIVE = 'objectives.change_objective';
export const PERMISSION_OBJECTIVES_CHANGE_OBJECTIVEHISTORY = 'objectives.change_objectivehistory';
export const PERMISSION_OBJECTIVES_DELETE_OBJECTIVE = 'objectives.delete_objective';
export const PERMISSION_OBJECTIVES_DELETE_OBJECTIVEHISTORY = 'objectives.delete_objectivehistory';
export const PERMISSION_OBJECTIVES_VIEW_OBJECTIVE = 'objectives.view_objective';
export const PERMISSION_OBJECTIVES_VIEW_OBJECTIVEHISTORY = 'objectives.view_objectivehistory';
export const PERMISSION_ONE_PAGER_ADD_ONEPAGER = 'one_pager.add_onepager';
export const PERMISSION_ONE_PAGER_ADD_ONEPAGERCOMMENT = 'one_pager.add_onepagercomment';
export const PERMISSION_ONE_PAGER_ADD_ONEPAGERKEYRESULT = 'one_pager.add_onepagerkeyresult';
export const PERMISSION_ONE_PAGER_ADD_ONEPAGEROBJECTIVE = 'one_pager.add_onepagerobjective';
export const PERMISSION_ONE_PAGER_CHANGE_ONEPAGER = 'one_pager.change_onepager';
export const PERMISSION_ONE_PAGER_CHANGE_ONEPAGERCOMMENT = 'one_pager.change_onepagercomment';
export const PERMISSION_ONE_PAGER_CHANGE_ONEPAGERKEYRESULT = 'one_pager.change_onepagerkeyresult';
export const PERMISSION_ONE_PAGER_CHANGE_ONEPAGEROBJECTIVE = 'one_pager.change_onepagerobjective';
export const PERMISSION_ONE_PAGER_DELETE_ONEPAGER = 'one_pager.delete_onepager';
export const PERMISSION_ONE_PAGER_DELETE_ONEPAGERCOMMENT = 'one_pager.delete_onepagercomment';
export const PERMISSION_ONE_PAGER_DELETE_ONEPAGERKEYRESULT = 'one_pager.delete_onepagerkeyresult';
export const PERMISSION_ONE_PAGER_DELETE_ONEPAGEROBJECTIVE = 'one_pager.delete_onepagerobjective';
export const PERMISSION_ONE_PAGER_VIEW_ONEPAGER = 'one_pager.view_onepager';
export const PERMISSION_ONE_PAGER_VIEW_ONEPAGERCOMMENT = 'one_pager.view_onepagercomment';
export const PERMISSION_ONE_PAGER_VIEW_ONEPAGERKEYRESULT = 'one_pager.view_onepagerkeyresult';
export const PERMISSION_ONE_PAGER_VIEW_ONEPAGEROBJECTIVE = 'one_pager.view_onepagerobjective';
export const PERMISSION_TESTING_EXAMPLES_ADD_ROSTER = 'testing_examples.add_roster';
export const PERMISSION_TESTING_EXAMPLES_ADD_SCHOOL = 'testing_examples.add_school';
export const PERMISSION_TESTING_EXAMPLES_ADD_SCHOOLCOURSE = 'testing_examples.add_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_ADD_STUDENT = 'testing_examples.add_student';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_ROSTER = 'testing_examples.change_roster';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_SCHOOL = 'testing_examples.change_school';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_SCHOOLCOURSE = 'testing_examples.change_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_STUDENT = 'testing_examples.change_student';
export const PERMISSION_TESTING_EXAMPLES_DELETE_ROSTER = 'testing_examples.delete_roster';
export const PERMISSION_TESTING_EXAMPLES_DELETE_SCHOOL = 'testing_examples.delete_school';
export const PERMISSION_TESTING_EXAMPLES_DELETE_SCHOOLCOURSE = 'testing_examples.delete_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_DELETE_STUDENT = 'testing_examples.delete_student';
export const PERMISSION_TESTING_EXAMPLES_VIEW_ROSTER = 'testing_examples.view_roster';
export const PERMISSION_TESTING_EXAMPLES_VIEW_SCHOOL = 'testing_examples.view_school';
export const PERMISSION_TESTING_EXAMPLES_VIEW_SCHOOLCOURSE = 'testing_examples.view_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_VIEW_STUDENT = 'testing_examples.view_student';
export const PERMISSION_SESSIONS_ADD_SESSION = 'sessions.add_session';
export const PERMISSION_SESSIONS_CHANGE_SESSION = 'sessions.change_session';
export const PERMISSION_SESSIONS_DELETE_SESSION = 'sessions.delete_session';
export const PERMISSION_SESSIONS_VIEW_SESSION = 'sessions.view_session';
export const PERMISSION_DB_ADD_TESTMODEL = 'db.add_testmodel';
export const PERMISSION_DB_CHANGE_TESTMODEL = 'db.change_testmodel';
export const PERMISSION_DB_DELETE_TESTMODEL = 'db.delete_testmodel';
export const PERMISSION_DB_VIEW_TESTMODEL = 'db.view_testmodel';

export class PermissionMap {
    static PERMISSION_GROUP_MAP = {
    "admin.add_logentry": [
        "admin",
        "colaborador"
    ],
    "admin.change_logentry": [
        "admin",
        "colaborador"
    ],
    "admin.delete_logentry": [
        "admin",
        "colaborador"
    ],
    "admin.view_logentry": [
        "admin",
        "colaborador"
    ],
    "auth.add_group": [
        "admin",
        "colaborador"
    ],
    "auth.add_permission": [
        "admin",
        "colaborador"
    ],
    "auth.change_group": [
        "admin",
        "colaborador"
    ],
    "auth.change_permission": [
        "admin",
        "colaborador"
    ],
    "auth.delete_group": [
        "admin",
        "colaborador"
    ],
    "auth.delete_permission": [
        "admin",
        "colaborador"
    ],
    "auth.view_group": [
        "admin",
        "colaborador"
    ],
    "auth.view_permission": [
        "admin",
        "colaborador"
    ],
    "authentication.add_account": [
        "admin",
        "colaborador"
    ],
    "authentication.add_verificationtoken": [
        "admin",
        "colaborador"
    ],
    "authentication.change_account": [
        "admin",
        "colaborador"
    ],
    "authentication.change_verificationtoken": [
        "admin",
        "colaborador"
    ],
    "authentication.delete_account": [
        "admin",
        "colaborador"
    ],
    "authentication.delete_verificationtoken": [
        "admin",
        "colaborador"
    ],
    "authentication.view_account": [
        "admin",
        "colaborador"
    ],
    "authentication.view_verificationtoken": [
        "admin",
        "colaborador"
    ],
    "contenttypes.add_contenttype": [
        "admin",
        "colaborador"
    ],
    "contenttypes.change_contenttype": [
        "admin",
        "colaborador"
    ],
    "contenttypes.delete_contenttype": [
        "admin",
        "colaborador"
    ],
    "contenttypes.view_contenttype": [
        "admin",
        "colaborador"
    ],
    "db.add_testmodel": [
        "admin",
        "colaborador"
    ],
    "db.change_testmodel": [
        "admin",
        "colaborador"
    ],
    "db.delete_testmodel": [
        "admin",
        "colaborador"
    ],
    "db.view_testmodel": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.add_clockedschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.add_crontabschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.add_intervalschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.add_periodictask": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.add_periodictasks": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.add_solarschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.change_clockedschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.change_crontabschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.change_intervalschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.change_periodictask": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.change_periodictasks": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.change_solarschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.delete_clockedschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.delete_crontabschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.delete_intervalschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.delete_periodictask": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.delete_periodictasks": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.delete_solarschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.view_clockedschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.view_crontabschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.view_intervalschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.view_periodictask": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.view_periodictasks": [
        "admin",
        "colaborador"
    ],
    "django_celery_beat.view_solarschedule": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.add_chordcounter": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.add_groupresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.add_taskresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.change_chordcounter": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.change_groupresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.change_taskresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.delete_chordcounter": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.delete_groupresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.delete_taskresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.view_chordcounter": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.view_groupresult": [
        "admin",
        "colaborador"
    ],
    "django_celery_results.view_taskresult": [
        "admin",
        "colaborador"
    ],
    "emails.add_email": [
        "admin",
        "colaborador"
    ],
    "emails.add_emailtemplate": [
        "admin",
        "colaborador"
    ],
    "emails.change_email": [
        "admin",
        "colaborador"
    ],
    "emails.change_emailtemplate": [
        "admin",
        "colaborador"
    ],
    "emails.delete_email": [
        "admin",
        "colaborador"
    ],
    "emails.delete_emailtemplate": [
        "admin",
        "colaborador"
    ],
    "emails.view_email": [
        "admin",
        "colaborador"
    ],
    "emails.view_emailtemplate": [
        "admin",
        "colaborador"
    ],
    "files.add_file": [
        "admin",
        "colaborador"
    ],
    "files.change_file": [
        "admin",
        "colaborador"
    ],
    "files.delete_file": [
        "admin",
        "colaborador"
    ],
    "files.view_file": [
        "admin",
        "colaborador"
    ],
    "kanban.add_etapa": [
        "admin",
        "colaborador"
    ],
    "kanban.add_ticket": [
        "admin",
        "colaborador"
    ],
    "kanban.change_etapa": [
        "admin",
        "colaborador"
    ],
    "kanban.change_ticket": [
        "admin",
        "colaborador"
    ],
    "kanban.delete_etapa": [
        "admin",
        "colaborador"
    ],
    "kanban.delete_ticket": [
        "admin",
        "colaborador"
    ],
    "kanban.view_etapa": [
        "admin",
        "colaborador"
    ],
    "kanban.view_ticket": [
        "admin",
        "colaborador"
    ],
    "key_results.add_analisedetalhada": [
        "admin",
        "colaborador"
    ],
    "key_results.add_checkin": [
        "admin",
        "colaborador"
    ],
    "key_results.add_keyresult": [
        "admin",
        "colaborador"
    ],
    "key_results.add_keyresulthistory": [
        "admin",
        "colaborador"
    ],
    "key_results.add_planoacao": [
        "admin",
        "colaborador"
    ],
    "key_results.change_analisedetalhada": [
        "admin",
        "colaborador"
    ],
    "key_results.change_checkin": [
        "admin",
        "colaborador"
    ],
    "key_results.change_keyresult": [
        "admin",
        "colaborador"
    ],
    "key_results.change_keyresulthistory": [
        "admin",
        "colaborador"
    ],
    "key_results.change_planoacao": [
        "admin",
        "colaborador"
    ],
    "key_results.delete_analisedetalhada": [
        "admin",
        "colaborador"
    ],
    "key_results.delete_checkin": [
        "admin",
        "colaborador"
    ],
    "key_results.delete_keyresult": [
        "admin",
        "colaborador"
    ],
    "key_results.delete_keyresulthistory": [
        "admin",
        "colaborador"
    ],
    "key_results.delete_planoacao": [
        "admin",
        "colaborador"
    ],
    "key_results.view_analisedetalhada": [
        "admin",
        "colaborador"
    ],
    "key_results.view_checkin": [
        "admin",
        "colaborador"
    ],
    "key_results.view_keyresult": [
        "admin",
        "colaborador"
    ],
    "key_results.view_keyresulthistory": [
        "admin",
        "colaborador"
    ],
    "key_results.view_planoacao": [
        "admin",
        "colaborador"
    ],
    "logger.add_log": [
        "admin",
        "colaborador"
    ],
    "logger.change_log": [
        "admin",
        "colaborador"
    ],
    "logger.delete_log": [
        "admin",
        "colaborador"
    ],
    "logger.view_log": [
        "admin",
        "colaborador"
    ],
    "objectives.add_objective": [
        "admin",
        "colaborador"
    ],
    "objectives.add_objectivehistory": [
        "admin"
    ],
    "objectives.change_objective": [
        "admin",
        "colaborador"
    ],
    "objectives.change_objectivehistory": [
        "admin"
    ],
    "objectives.delete_objective": [
        "admin",
        "colaborador"
    ],
    "objectives.delete_objectivehistory": [
        "admin"
    ],
    "objectives.view_objective": [
        "admin",
        "colaborador"
    ],
    "objectives.view_objectivehistory": [
        "admin"
    ],
    "one_pager.add_onepager": [],
    "one_pager.add_onepagercomment": [],
    "one_pager.add_onepagerkeyresult": [],
    "one_pager.add_onepagerobjective": [],
    "one_pager.change_onepager": [],
    "one_pager.change_onepagercomment": [],
    "one_pager.change_onepagerkeyresult": [],
    "one_pager.change_onepagerobjective": [],
    "one_pager.delete_onepager": [],
    "one_pager.delete_onepagercomment": [],
    "one_pager.delete_onepagerkeyresult": [],
    "one_pager.delete_onepagerobjective": [],
    "one_pager.view_onepager": [],
    "one_pager.view_onepagercomment": [],
    "one_pager.view_onepagerkeyresult": [],
    "one_pager.view_onepagerobjective": [],
    "organizational_structure.add_area": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.add_company": [],
    "organizational_structure.add_period": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.add_team": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.change_area": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.change_company": [
        "admin"
    ],
    "organizational_structure.change_period": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.change_team": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.delete_area": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.delete_company": [
        "admin"
    ],
    "organizational_structure.delete_period": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.delete_team": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.view_area": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.view_company": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.view_period": [
        "admin",
        "colaborador"
    ],
    "organizational_structure.view_team": [
        "admin",
        "colaborador"
    ],
    "sessions.add_session": [
        "admin",
        "colaborador"
    ],
    "sessions.change_session": [
        "admin",
        "colaborador"
    ],
    "sessions.delete_session": [
        "admin",
        "colaborador"
    ],
    "sessions.view_session": [
        "admin",
        "colaborador"
    ],
    "testing_examples.add_roster": [
        "admin",
        "colaborador"
    ],
    "testing_examples.add_school": [
        "admin",
        "colaborador"
    ],
    "testing_examples.add_schoolcourse": [
        "admin",
        "colaborador"
    ],
    "testing_examples.add_student": [
        "admin",
        "colaborador"
    ],
    "testing_examples.change_roster": [
        "admin",
        "colaborador"
    ],
    "testing_examples.change_school": [
        "admin",
        "colaborador"
    ],
    "testing_examples.change_schoolcourse": [
        "admin",
        "colaborador"
    ],
    "testing_examples.change_student": [
        "admin",
        "colaborador"
    ],
    "testing_examples.delete_roster": [
        "admin",
        "colaborador"
    ],
    "testing_examples.delete_school": [
        "admin",
        "colaborador"
    ],
    "testing_examples.delete_schoolcourse": [
        "admin",
        "colaborador"
    ],
    "testing_examples.delete_student": [
        "admin",
        "colaborador"
    ],
    "testing_examples.view_roster": [
        "admin",
        "colaborador"
    ],
    "testing_examples.view_school": [
        "admin",
        "colaborador"
    ],
    "testing_examples.view_schoolcourse": [
        "admin",
        "colaborador"
    ],
    "testing_examples.view_student": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.add_blacklistedtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.add_outstandingtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.change_blacklistedtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.change_outstandingtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.delete_blacklistedtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.delete_outstandingtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.view_blacklistedtoken": [
        "admin",
        "colaborador"
    ],
    "token_blacklist.view_outstandingtoken": [
        "admin",
        "colaborador"
    ],
    "users.add_baseuser": [
        "admin"
    ],
    "users.add_invite": [
        "admin"
    ],
    "users.add_tenant": [
        "admin",
        "colaborador"
    ],
    "users.change_baseuser": [
        "admin"
    ],
    "users.change_invite": [
        "admin"
    ],
    "users.change_tenant": [
        "admin",
        "colaborador"
    ],
    "users.delete_baseuser": [
        "admin"
    ],
    "users.delete_invite": [
        "admin",
        "colaborador"
    ],
    "users.delete_tenant": [
        "admin"
    ],
    "users.list_baseuser": [
        "admin"
    ],
    "users.list_invite": [
        "admin"
    ],
    "users.view_baseuser": [
        "admin",
        "colaborador"
    ],
    "users.view_invite": [
        "admin",
        "colaborador"
    ],
    "users.view_tenant": [
        "admin",
        "colaborador"
    ]
}
static hasPermission(permission, role) {
return this.PERMISSION_GROUP_MAP[permission].includes(role);}};